import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React, { useRef } from "react"
import Slider from "react-slick"
import { AspectRatio, Box, Flex } from "theme-ui"

import BlockContainer from "./block-container"
import BlockTitle from "./block-title"

const ContentfulSliderBlock = ({
  heading = 'This is how we roll:',
  slides,
  ratioed = true
}) => {
  const slider = useRef()

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <BlockContainer>
        <BlockTitle title={heading} />
        <Box sx={{ mt: [4, 4, 5, 5] }}>
          <Slider
            ref={slider}
            dots={true}
            appendDots={(dots) => (
              <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
                <Box
                  onClick={() => {
                    slider.current.slickPrev()
                  }}
                  sx={{
                    ":hover": {
                      opacity: 1,
                    },
                    cursor: "pointer",
                    opacity: 0.4,
                  }}
                >
                  ←
                </Box>
                <Box sx={{ mt: "-9px", mx: "10px" }}>{dots}</Box>
                <Box
                  onClick={() => {
                    slider.current.slickNext()
                  }}
                  sx={{
                    ":hover": {
                      opacity: 1,
                    },
                    cursor: "pointer",
                    opacity: 0.4,
                  }}
                >
                  →
                </Box>
              </Flex>
            )}
            infinite={false}
            speed={500}
            slidesToScroll={1}
            slidesToShow={1.5}
          >
            {ratioed && slides?.map((slide, index) => {
              return (<AspectRatio
                ratio={2 / 1.5}
                sx={{
                  boxSizing: "border-box",
                  height: "60vh",
                  outline: "none",
                  px: 3,
                }}
                key={`slide-${index}`}
              >
                <img
                  src={slide?.resize?.src}
                  style={{ height: "100%", objectFit: "cover", width: "100%" }}
                />
              </AspectRatio>)
            })}
            {!ratioed && slides?.map((slide, index) => {
              return (<img
                key={`image-${index}`}
                src={slide?.resize?.src}
                style={{ height: "auto", display: "block", width: "100%" }}
              />)
            })}
          </Slider>
        </Box>
      </BlockContainer>
    </Box>
  )
}

export default ContentfulSliderBlock
